/* ******************************************************************
|| BASE CSS */

html {
    scroll-behavior: smooth;
}

body {
    background-color: black;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* ******************************************************************
|| NAV SECTION */

nav {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 20px 20px;
    position: relative;
    margin-bottom: 25px;
}

nav h1 {
    color: white;
    margin: 0;
    position: absolute;
    /* left: 20px; */
}

nav a {
    color: white;
}

nav a:hover {
    color: rgb(0, 56, 101);
}

nav ul {
    display: inline-flex;
    gap: 1.9rem;
    position: absolute;
    right: 9%;
    list-style-type: none;
}

nav ul.hidden {
    display: none;
}

nav ul li {
    font-weight: 700;
    margin-right: -150px;
    margin-top: 20px;
}

.burger-menu {
    display: flex;
    flex-direction: column;
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 14px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-bottom: 4px;
}

/* ******************************************************************
|| HOME PAGE */

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 99%;
    gap: 5rem;
    margin: auto;
    position: relative;
}

.home img {
    height: 100%;
    width: 100%;
    margin: 25px;
}

.btn {
    justify-content: center;
    text-align: center;
}

.pvt-btn {
    border-radius: 20px;
    margin: auto;
    background-color: rgb(0, 56, 101);
    border: gray;
    color: white;
    padding: 25px 42px;
    font-size: 30px;
    font-weight: 700;
    text-decoration: none;
}

.pvt-btn:hover {
    cursor: pointer;
    background-color: rgba(0, 56, 101, 0.622)
}

/* ******************************************************************
|| HERO SECTION  */

.player-wrapper {
    display: flex;
    justify-content: center;
}

.hero-quote {
    color: white;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    max-width: 1400px;
}

.quote {
    font-size: 37px;
}

.tilde {
    font-size: 60px;
    margin-top: -3px;
}

.hero {
    color: white;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    max-width: 1000px;
}


.hero img {
    height: 45%;
    width: 45%;
    border-radius: 3px;
    border: solid 5px rgb(0, 56, 101);
    box-shadow: 0px 3px 14px rgba(255, 255, 255, 0.612);
    margin-bottom: -5px;
}

.bio {
    font-size: 26px;
    line-height: 1.35;
    margin-top: 55px;
}

/* ******************************************************************
|| CONTACT SECTION */

.contact-form {
    text-align: left;
}

.contact-form div {
    padding: 0px 0px 6px 0px;
    display: flex;
    align-items: center;
}

.contact-form label {
    padding-right: 10px;
    flex: .5;
}

.contact-form input {
    padding: 10px;
}

#firstName {
    margin-right: -5px;
}

.sub-btn {
    align-self: center;
    margin-top: 5px;
    padding: 10px 27px;
    font-size: 17px;
}

#message {
    width: 250px;
    height: 100px;
}


.contact-modal {
    position: fixed;
    z-index: 1;
    left: 25%;
    top: 25%;
    width: 50%;
    height: 61%;
    overflow: auto;
    background-color: rgb(0, 56, 101, .5);
}

.contact-modal-content {
    background-color: rgba(0, 0, 0, .7);
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    margin-left: -15px;
}

/* ******************************************************************
|| VIDEO SECTION  */

.videos {
    position: relative;
    padding: 32px 0;
    margin-top: 5px;
}

.video-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-card {
    border-radius: 3px;
    background-color: rgb(0, 56, 101);
    box-shadow: 0px 3px 14px rgba(255, 255, 255, 0.612);
    padding: 5px 5px 4px 5px;
    margin: 10px;
    margin-bottom: 50px;
}

/* ******************************************************************
|| LESSONS */
.lessons {
    max-width: 75rem;
    height: auto;
    margin: 4rem auto;
    text-align: center;
    z-index: -1;
    display: flex;
    flex-direction: row;
    /* Horizontal layout by default */
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.lessons img {
    width: 60%;
    /* Dynamic width, adjusts with container */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 3px;
    border: solid 5px rgb(0, 56, 101);
    box-shadow: 0px 3px 14px rgba(255, 255, 255, 0.612);
}

.lessons-container-wrapper {
    display: flex;
    flex-direction: column;
    /* Stack lessons vertically */
    gap: 1rem;
    justify-content: center;
    width: 100%;
}

.lessons-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 25px 0;
    width: 100%;
}

.lesson {
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 25rem;
    /* Ensure consistent width */
    height: 8rem;
    font-weight: bold;
    font-size: 2rem;
    padding: 20px;
    border: 20px solid rgb(0, 56, 101);
    background-color: rgb(0, 56, 101);
    color: white;
}



.studio-policy {
    color: white;
    width: 60%;
    margin: 0 auto;
}

.studio-policy h1 {
    margin-left: -60px;
    font-size: 35px;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}

.studio-policy h2,
.studio-policy p {
    text-align: left;
}

.studio-policy h2 {
    font-size: 23px;
    margin-bottom: -5px;
}

.studio-policy p {
    font-size: 20px;
    line-height: 1.25;
    margin-left: 50px;
}

.lsn-btn {
    margin-top: 100px;
    margin-bottom: 100px;
}

/* ******************************************************************
|| ADMIN SECTION */

.login-form,
.contact-list {
    text-align: center;
}

.adm {
    margin-bottom: 75px;
    text-decoration: underline;
}

.log:hover {
    background-color: rgba(0, 56, 101, 0.622);
    cursor: pointer;
    color: white;
}

.contact-list button:hover {
    background-color: rgba(0, 56, 101, 0.622);
    cursor: pointer;
    color: white;
}


.login-form input {
    display: block;
    margin: 10px auto;
    padding: 5px;
}

.login-form button {
    padding: 5px;
    margin: 10px;
}

.con {
    margin: 100px 0 20px 0;
    text-decoration: underline;
}

.contact-list table {
    margin: 0 auto;
    /* Center the table */
    border-collapse: collapse;
    /* Collapse border spacing */
    width: 80%;
    /* Adjust the width as needed */
}

.contact-list th,
.contact-list td {
    border: 1px solid #fff;
    padding: 8px;
    text-decoration: none;
}

.contact-list th {
    background-color: rgb(0, 56, 101);
}

.contact-list tr:nth-child(even) {
    background-color: rgb(0, 56, 101);
}

/* ******************************************************************
|| SOCIAL LINKS */
.socials {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 1.1%;
    bottom: 50%
}

.socicon {
    width: 2rem;
    height: 2rem;
}

/* ******************************************************************
|| FOOTER */

footer {
    padding: 1px;
    text-align: center;
    margin: 14px 0 0;
    font-size: 12px;
    font-weight: normal;
}

.copy {
    margin-bottom: -7px;
}

footer a {
    color: rgb(0, 56, 101);
}

/* ******************************************************************
|| SIZING  */

@media (max-width: 768px) {

    nav ul li {
        margin-right: 25px;
    }

    .player-wrapper {
        width: 575px;
    }

    .lsn-btn {
        margin-top: -55px;
        margin-bottom: 35px;
    }

    .quote {
        font-size: 25px;
        margin-top: 2px;
    }

    .tilde {
        font-size: 45px;
        margin-bottom: 2px;
    }

    .bio {
        font-size: 19px;
        margin-bottom: 50px;
    }

    .hero {
        width: 86%;
        margin-bottom: -45px;
    }

    .hero h1 {
        font-size: 27px;
    }

    .hero img {
        height: 70%;
        width: 70%;
    }

    .contact-form label {
        padding-right: 10px;
        flex: .8;
    }

    #firstName {
        margin-right: 0px;
    }

    #message {
        width: 220px;
    }

    .sub-btn {
        font-size: 15px;
    }

    .contact-modal {
        left: 5%;
        top: 5%;
        width: 90%;
        height: 57%;
    }

    .close {
        font-size: 25px;
        margin-right: -11px;
        margin-top: -15px;
    }

    .videos {
        margin-top: 0px;

    }

    .video-container {
        flex-direction: column;
    }

    .lessons {
        flex-direction: column;
        /* Stack image on top of lessons */
    }

    .lessons img {
        width: 100%;
        /* Full width for mobile */
    }

    .lessons-container-wrapper {
        width: 100%;
    }

    .lessons-container {
        width: 100%;
    }

    .lesson {
        width: 100%;
    }

    .studio-policy {
        width: 90%;
        margin-bottom: 100px;
    }
}